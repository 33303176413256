:root {
    --amplify-primary-color: #0FA0CE;
    --amplify-primary-tint: #0FA0CE;
    --amplify-primary-shade: #038761;
    --amplify-font-family: 'Nunito Sans', sans-serif;
    --amplify-text-xxs: 1.5rem;
    --amplify-text-xs: 1.7rem;
    --amplify-text-sm: 2rem;
    --amplify-text-md: 2.5rem;
    --amplify-text-lg: 3rem;
    --amplify-text-xl: 3.5rem;
    --amplify-text-xxl: 4rem;
}


amplify-authenticator {
    --container-display: block;
    --width: 100%;
    --container-height: auto;
    --box-shadow: none;
    --padding: 0;
}