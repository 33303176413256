

.skip{
padding: 0 0.5em;
display: inline;
z-index: 2;
text-decoration: none;
position: absolute;
width: 14em;
left: -200em;
}


.sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
	width: 1px;
	color:#000;
  }

/*HELPER STYLES*/

.text-center {
	text-align: center;
}

.m-auto {
	margin: auto!important;
}

.flex {
	display: flex;
}


/*HELPER STYLES - FOnt sizes*/


/*text Large - 60pt / 50pt / 40pt */
.text-x-large {
	font-size: 3rem;
	line-height: 3.2rem;
}
@media screen and (min-width: 768px) and (max-width:1023px) {
	.text-x-large {
        font-size: 4rem;
        line-height: 5rem;
	}
}
@media screen and (min-width: 1024px) {
	.text-x-large {
        font-size: 5rem;
        line-height: 6rem;
	}
}

@media screen and (min-width: 1281px) {
	.text-x-large {
		font-size: 6rem;
	}
}


/*text Large - 36pt / h2- 26pt / h3- 23pt */

h2.text-large {
	font-size: 2.6rem;
	line-height: 2.75rem;
}

h3.text-large {
	font-size: 2.3rem;
	line-height: 2.3rem;
}

@media screen and (min-width: 1281px) {
	h2.text-large {
		font-size: 3.6rem;
		line-height: 3.75rem;
	}

	h3.text-large {
		font-size: 3.2rem;
		line-height: 3.5rem;
	}
}


/*text medium large - 30pt / 24pt */

.text-medium-large {
    font-size: 2.4rem;
    line-height:1.4em;
}
@media screen and (min-width: 1281px) {
	.text-medium-large {
        font-size: 3rem;
    }
}


/*text medium large - 28pt / 24px */
.text-medium {
	font-size: 2.4rem;
}
@media screen and (min-width: 1281px) {
	.text-medium {
        font-size: 2.8rem;
    }
}









/*HELPER STYLES - padding*/

.padding-y-14 {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

@media screen and (min-width: 1281px) {
	.padding-y-14 {
		padding-top: 14rem;
		padding-bottom: 14rem;
	}
}



.padding-y-20 {
	padding-top: 6rem;
	padding-bottom: 4rem;
}

@media screen and (min-width: 1280px) {
	.padding-y-20 {
		padding-top: 20rem;
		padding-bottom: 10rem;
	}
}




.padding-y-8 {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media screen and (min-width: 1281px) {
	.padding-y-8 {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}
}

.padding-x-8 {
	padding-left: 2rem;
	padding-right: 2rem;
}

@media screen and (min-width: 1281px) {
	.padding-x-8 {
		padding-left: 8rem;
		padding-right: 8rem;
	}
}


/*HELPER STYLES - CONTAINER WIDTHS*/

.container-x-wide {
	width: 100%;
	margin: auto;
	max-width: unset;
}

@media screen and (min-width: 1281px) {
	.container-x-wide {
		width: 90%;
		margin: auto;
	}
}


/*ACCESABLE HIDDEN - HIDES ON SCREEN BUT IS AVABLE VIA SCREEN READER*/

.accessible-hidden {
	clip: rect(1px 1px 1px 1px);
	/* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
}

a:focus {
	outline: auto 2px Highlight;
	outline: auto 5px -webkit-focus-ring-color;
}


/*FONTS*/

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Nunito Sans', sans-serif;
	position: relative;
	margin-bottom: 6rem;
}


/*TITLE DECORATION*/

h1:after,
h2:after,
h3:after,
h4:after {
	content: '';
	width: 100%;
	border-bottom: solid 5px #038761;
	position: absolute;
	left: 0;
	bottom: -2rem;
	z-index: 0;
	width: 6rem;
}

/*REMOVE TITLE DECORATION*/
.accordion-container h2:after,
.card-download h3:after,
h2.step-indicator:after,
.step h2:after,
.step h3:after,
.step h4:after,
.step h5:after {
        display: none;
 }








.hero h1:after{
    border-bottom: solid 5px #fff;
    bottom: -4rem;
}
.footer h2:after {
	border-bottom: solid 5px #fff;
    bottom: -2rem;
}

.center h2 {
	display: inline-block;
}

.center h1:after,
.center h2:after,
.center h3:after,
.center h4:after {
	content: '';
	transform: translateX(-50%);
	left: 50%;
}


/*SECTION SPACING*/

section {
	padding: 5rem 0 5rem 0;
}

div,
nav .card,
.progress-card,
.accordion-container .card button,
header nav.main-menu.active{
	-webkit-box-sizing: border-box;
	/* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;
	/* Firefox, other Gecko */
	box-sizing: border-box;
}


/*COLOURS*/

.bg-white h1,
.bg-white h2,
.bg-white h3,
.bg-white h4,
.bg-white h5,
.bg-white h6,
.bg-white p {
	background-color: #ffffff;
}

.bg-white h1,
.bg-white h2,
.bg-white h3,
.bg-white h4,
.bg-white h5,
.bg-white h6,
.bg-white p {
	color: #222;
}

.bg-gray {
	background-color: #f4f4f4;
}


/*HEADER */


/*LOGO*/

.logo img {
	max-width: 200px;
}

.hidden {
	display: none;
}


/*NAVIGATION*/

#header .container .row {
	box-sizing: border-box;
	padding: 3rem 0 4rem 0;
	display: table;
	width: 100%;
	height: 100px;
}

.logo,
.menu {
	vertical-align: middle;
	display: table-cell;
	float: unset;
}

#header .menu {
	box-sizing: border-box;
	width: 100%;
}

.main-menu {
	text-align: right;
}

.main-menu ul,
.main-menu li {
	margin: 0px;
}

.main-menu li a {
	font-weight: 500;
	font-size: 1.8rem;
}

@media screen and (min-width: 1380px) {
	#header .container .row {
		padding: 5rem 0 5rem 0;
	}
	.main-menu li a {
		font-weight: 500;
		font-size: 1.9rem;
	}
}

header .main-menu-toggle {
	appearance: none;
	background: transparent;
	border: 0;
	cursor: pointer;
	float: left;
}

@media screen and (min-width: 1280px) and (max-width: 1350px){
	#header .three.columns{
		width: 20%;
	}
}


@media screen and (min-width: 700px) and (max-width: 1270px){

	#header .container{
		width:90%;
	}
}


@media screen and (max-width: 1279px) {
	header .main-menu-toggle {
		float: right;
    }

}

header .main-menu-toggle svg {
	fill: currentColor;
}

header nav.main-menu {
	display: none;
	width: 100%;
	z-index: 1;
}

header nav.main-menu.active {
	display: block;
	background-color: #fff;
	z-index:10;
}

header nav.main-menu a {
	display: block;
	padding: .5em 0;
	text-decoration: none;
}

header nav.main-menu a.active,
header nav.main-menu a:hover {
	background-color: #0070b0;
	color: #fff;
}

header nav.main-menu a:focus {
	outline: 2px solid;
}

header input#hamburgerMenu:checked~nav {
	display: block;
}

@media screen and (max-width: 1024px) {
	header nav.main-menu {
		background-color: #003469;
		display: none;
		left: 0;
		position: absolute;
		top: 110px;
		width: 100%;
		padding: 5vw;
	}
	header nav.main-menu.active {
		display: block;
		background-color: #003469;
		color: #fff;
	}
	header nav.main-menu li {
		display: block;
		text-align: left;
	}
	header nav a {
		color: #fff;
	}
}

@media screen and (min-width: 1279px) {
	header .main-menu-toggle {
		display: none;
	}

}

@media screen and (min-width: 1280px) {

	header nav.main-menu {
		display: block;
		width: 100%;
		background-color: #ffffff;
	}
	.main-menu li:first-child{
		display:hidden;
	}
}

@media screen and (min-width: 1025px) {


	header nav.main-menu a.active,
	header nav.main-menu a:hover {
		background-color: inherit;
		box-shadow: inset 0 -2px #038761;
		color: #333;
	}
	.main-menu li {
		display: inline-block;
		width: auto;
		margin: 0 0.9rem;
		text-align: center;
		background-color: #fff;
	}
	nav a {
		background-color: #ffffff;
		color: #222;
		padding: 0 1rem;
	}
}

@media screen and (min-width: 1530px) {
	.main-menu li {
		display: inline-block;
		width: auto;
		margin: 0 1.5rem;
	}
}

/*HERO*/

.hero {
	width: 90%;
	margin: auto;
	background: #097FAF;
	color: #fff;
	padding: 0px;
	background-image: linear-gradient( to right, #005b97, #013353);
}
.hero .hero-content h1 {
    margin-bottom: 10rem;
}

.hero .hero-content {
	padding: 3rem;
	position: relative;
	z-index: 1;
}

.hero .hero-imagery {
	position: relative;
	background-image: url(../images/hero/hero-shapes.png);
	background-repeat: no-repeat;
}

.hero .hero-image {
	position: absolute;
}
.hero-container .button{
	color:#097FAF;
	background-color: #FFF;
	border-color: #FFF;
	letter-spacing: normal;
	text-transform: none;
}

.hero-container .button:hover{
	color: #FFF;
	background-color: #038761;
	border-color: #fff;
}



@media screen and (max-width: 700px) {
	.hero .hero-content h1 {
        padding-top: 10rem;
        width: 70%;
	}
	.hero-container .button{
		font-size:1.5rem;

	}
}
@media screen and (max-width: 1023px) {
	.hero .hero-container {
		position: relative;
	}
	.hero .hero-content h1 {
		padding-top: 4rem;
		width: 60%;
	}
	.hero .hero-image {
		top: -3rem;
		right: 8%;
		width: 15%;
	}
	.hero .hero-imagery {
		position: absolute;
		background-position: right 0px;
		background-size: 31%;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 100%;

	}
}
@media screen and (min-width: 700px) and (max-width: 1023px){
	.hero .hero-content {
		padding-right: 22rem;
	}
	.hero .hero-content h1{
		width: 65%;
	}
}


@media screen and (min-width: 1024px) {
	.hero .hero-container {
		display: flex;
		-webkit-flex-direction: row-reverse;
		flex-direction: row-reverse;
	}
	.hero .hero-imagery {
		position: relative;
	}
}

@media (min-width: 1024px) and (max-width: 1529px) {
    .hero .hero-container {
		min-height: 380px;
    }
    .hero .hero-content h1 {
        padding-top: 4rem;
    }
	.hero .hero-image {
		top: -4rem;
		right: 27%;
        width: 47%;
	}
	.hero .hero-content {
		width: 70%;
		padding: 3rem;
    }

	.hero .hero-imagery {
		width: 30%;
		background-position: right 0px;
		background-position: top 2rem;
		background-size: cover;
	}
}


@media screen and (min-width: 1280px) {
	.hero .hero-content {
		padding: 5rem 8rem 5rem 5rem;
	}
	.hero .hero-content h1 {
		padding-top: 0rem;
	}
}

@media screen and (min-width: 1530px) {
	.hero .hero-container {
		min-height: 435px;
	}
	.hero .hero-image {
		top: -4rem;
		right: 18rem;
		width: auto;
		max-width: 18rem;

	}
	.hero .hero-content {
		width: 70%;
        padding: 8rem;
	}
	.hero .hero-imagery {
		width: 30%;
		background-position: right 0px;
		background-position: top 2rem;
		background-size: initial;
	}
}


/*INNER HERO*/


@media screen and (min-width: 1530px) {
	.hero.inner-hero .hero-container {
		min-height: 300px;
    }
    .hero.inner-hero .hero-imagery {
		background-size: 68%;
	}
}


/*INNER HERO - STATEMENT PAGE*/



.hero.inner-hero ul{
	list-style-type:none;
}
.hero.inner-hero ul li{
	padding-top: 1rem;
	padding-bottom: 1rem;
	margin: 0px 6rem 0px 0px;
	padding-left: 4rem;
	position:relative;
	border-bottom: 1px solid #fff;

}
.hero.inner-hero li a{
	color:#fff;
	text-decoration:none;
}
.hero.inner-hero ul li:before{
	content: "";
	width: 20px;
	height: 20px;
	position: absolute;
	top: 1.5rem;
	left: 0.5rem;
	background-image:  url(../images/icons/footer-arrow.png);
	background-position: center center;
	background-repeat:no-repeat;
	background-size:contain;
	z-index:1;

}
@media screen and (min-width: 1024px) {
	.hero.inner-hero ul{
	margin-left:0rem;
		columns: 2;
		-webkit-columns: 2;
		-moz-columns: 2;
	}
}






/*BREADCRUMBS*/
.breadcrumb-nav{

    padding: 0.8em 1em;
	margin-bottom:3rem;
}

  nav.breadcrumb ol {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

  nav.breadcrumb li {
    display: inline;
  }

  nav.breadcrumb li + li::before {
    display: inline-block;
    margin: 0 0.25em;
    transform: rotate(15deg);
    border-right: 0.1em solid currentColor;
    height: 0.8em;
    content: '';
  }

  nav.breadcrumb [aria-current="page"] {
    color: #000;
    font-weight: 700;
    text-decoration: none;
  }

  @media screen and (max-width: 780px) {
	nav.breadcrumb li{
		font-size: 1.4rem;
	}
  }
  @media screen and (max-width: 699px) {
	nav.breadcrumb{
		padding-left: 1rem;
	}
}
  @media screen and (min-width: 700px) and (max-width: 1280px) {
	nav.breadcrumb{
		padding-left: 3rem;
	}
}











/*FORMs*/

.label {
	display: block;
}

input[type=text],
textarea {
	width: 100%;
}
textarea {
	min-height:100px;
}


/*LOGIN FORM*/

.login-form legend {
	padding: 0 5px;
	text-align: right;
}

.login-form .form-name,
.login-form .form-email {
	min-height: 6vw;
}

.login-form fieldset>div {
	margin: 10px 0;
}

.login-form fieldset>legend+div {
	margin-top: 0;
}

.login-form fieldset>div:last-child {
	margin-bottom: 0;
}

.login-form label {
	display: block;
}

.login-form input {
	width: 100%;
}

.login-form div>input[type="submit"] {
	background: #ccc;

	width: auto;
	color: #FFF;
	background-color: #0A6C8A;
	border-color: #0A6C8A;
}

.login-form input:required {
	background: hsl(180, 50%, 90%);
	border: 1px solid #999;
}

.login-form input:optional {
	background: hsl(300, 50%, 90%);
	border: 1px dotted hsl(180, 50%, 90%);
}

.login-form input:valid,
.login-form input:in-range {
	background: hsl(120, 50%, 90%);
	border-color: hsl(120, 50%, 50%);
}

.login-form input:invalid,
.login-form input:out-of-range {
	border-color: hsl(0, 50%, 50%);
	background: hsl(0, 50%, 90%);
}

.login-form .help {
	display: none;
	font-size: 90%;
}

.login-form input:focus+.help {
	display: block;
}


















/*CARDS*/

.card {
	width: 100%;
	padding: 4rem;
	margin-bottom: 4rem;
	text-decoration: none;
	background: #fff;
	box-shadow: 0px 3px 18px 8px rgba(18, 38, 63, 0.15);
}

@media screen and (min-width: 1281px) {
	.card {
		padding: 4.5rem;
		margin-bottom: 4.5rem;
	}
}

@media screen and (min-width: 1530px) {
	.card {
		padding: 6rem;
		margin-bottom: 6rem;
	}
}

.card h2 {
	font-weight: 600;
}


/*CARDS ON ACTION FORMS*/

@media screen and (min-width: 1024px) {
	.card-form,
	.card-download {
		width: 110%;
	}
}

@media screen and (min-width: 1340px) {
	.card-form,
	.card-download {
		width: 120%;
	}
}

/*FORM STYLES ON ACTION FORMS*/



/* Hide the browser's default checkbox */
.approve-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }


 /* Customize the label (the container) */


 .approve-container {
	display: block;
	position: relative;
	padding-left: 6rem;
	margin-bottom: 6rem;
	cursor: pointer;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }

  /* Hide the browser's default checkbox */
  .approve-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 4rem;
	width: 4rem;
	background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .approve-container:hover input ~ .checkmark {
	background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .approve-container input:checked ~ .checkmark {
	background-color: #097faf;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }

  /* Show the checkmark when checked */
  .approve-container input:checked ~ .checkmark:after {
	display: block;
  }

  /* Style the checkmark/indicator */
  .approve-container .checkmark:after {
	left: 14px;
	top: 7px;
	width: 8px;
	height: 16px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
  }













/*CARDS ON DOWNLOADABLE RESOURCES */

.card-download {
	padding: 4rem;

}




@media screen and (max-width: 800px) {
	.card-download {
		overflow:auto;
	}
	.download-pdf{
		float:right;
		display:block;
		margin: 2rem 0 0 0;
	}
}

@media screen and (min-width: 1024px) {
	.card-download {
		justify-content: space-between;
		display: flex;
	}
	.download-title{
		max-width:60%;
	}
}

@media screen and (min-width: 1530px) {
	.card-download {
		padding: 3rem;
	}
}

.card-download h3:after {
	margin-bottom: 2rem;
}

.card-download h3 {
	margin-bottom: 2rem;
}

.download-pdf {
	display: flex;
	align-content: flex-end;
	align-self: flex-end;
	padding-right:5rem;
}
.download-pdf a{
	position:relative;
}
.download-pdf a:after{
	content: "";
	width: 50px;
	height: 51px;
	position: absolute;
	bottom: -0rem;
	right: -6rem;
	background-image:  url(../images/icons/download.png);
	background-position: center center;
	background-repeat:no-repeat;
	background-size:contain;
	z-index:1;
}

.card-download p {
	margin-bottom: 0px;
}













/*CARDS ON STATEMENT PAGE*/
.statement .card-row{
	display:flex;
	flex-wrap: wrap;
	justify-content: space-between;
}


.card.statement{
    position:relative;
	margin-bottom: 8rem;
	min-height:500px;
	height:auto !important;
	height:500px;

}

@media screen and (min-width: 700px) {

    .card.statement{
		padding-left:7rem;
		padding-right:7rem;


	}
}

@media screen and (min-width: 1024px) {

    .card.statement{
        width:47%;

    }
    .card.statement:nth-child(2){
        float:right;
    }
}
@media screen and (min-width: 1350px) {
    .card.statement{
        padding-left:10rem;
        width:45%;
        margin-bottom: 10rem;
    }
}


.statement:before{

content: "";
width: 80px;
height: 80px;
position: absolute;
top: -1.5rem;
right: 2.5rem;
border-radius: 80px;
box-shadow: 0px 3px 9px 1px rgba(18, 38, 63, 0.15);
background-position: center center;
background-repeat:no-repeat;
background-size:contain;
z-index:1;
}

.statement-actions:before{
    background-image:  url(../images/icons/statement-actions.png);
}
.statement-updates:before{
    background-image:  url(../images/icons/statement-updates.png);
}
.statement-key-dates:before{
    background-image:  url(../images/icons/statement-key-dates.png);
}
.statement-contact:before{
    background-image:  url(../images/icons/statement-contact.png);
}


@media screen and (min-width: 1024px) {
    .statement:before{

        content: "";
        width: 100px;
        height: 100px;
        top: -3.5rem;
        left: -3rem;
        box-shadow: 0px 3px 18px 8px rgba(18, 38, 63, 0.15);

}}

@media screen and (min-width: 1350px) {
    .statement:before{
        content: "";
        top: -4.5rem;
        left: -4rem;
        width: 120px;
        height: 120px;

}
}


/*STATEMENT CARD ITEMS - ACTIONS*/

.statement-actions p{
	margin-bottom:2rem;
}





/*STATEMNT CARD ITEMS - KEY DATES & UPDATES*/

ul.list-dates,
ul.list-contact{
    list-style-type:none;
}
ul.list-dates li{
	display:flex;
	justify-content: space-between;
	border-bottom:1px solid #353535;
    padding-top:1.5rem;
    padding-bottom:2.5rem;
    position:relative;
}
ul.list-dates li.completed:after{
    content: "";
    width: 20px;
    height: 26px;
    position: absolute;
    top: 1.5rem;
    right: -3rem;
    background-image: url(../images/icons/tick.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
@media screen and (min-width: 1440px) {
	ul.list-dates li.completed:after{
		width: 25px;
		height: 28px;
		top: 1.5rem;
		right: -4rem;

	}
}




/*STATEMNT CARD ITEMS - CONTACT*/


ul.list-contact li{
	border-bottom:1px solid #353535;
    padding-top:1.5rem;
   padding-bottom:2.5rem;
   position:relative;
}
.contact-provider,
.contact-tel{
	display:block
}







/*PROGRESS KEY*/

.process-key ul{
	list-style-type: none;
	margin: 6rem 0px 6rem 0rem;
	display:flex;
	justify-content: space-between;

}
.process-key ul li{
	position:relative;
	padding: 4rem 0 0 0rem;
}
.process-key ul li:before{
	content: "";
    width: 4rem;
    height: 4rem;
	position: absolute;

    top: 0rem;
	box-shadow: 0px 3px 3px 3px rgba(18, 38, 63, 0.1);
}
.process-key .key-completed:before{
	background-color:#038761;
	border:5px solid #038761;

}
.process-key .key-inprogress:before{
	background-color:#fff;
	border:5px solid #038761;

}
.process-key .key-pending:before{
	background-color:#fff;
	border:5px solid #b2b2b2;

}

@media screen and (max-width: 1024px) {
.process-key ul li:before{
	left: 50%;
  	transform: translate(-50%, -50%);
}
}

@media screen and (min-width: 1025px) {

	.process-key ul{
		margin: 2rem 0px 6rem 4rem;
		justify-content: space-between;
		width:550px;
	}
	.process-key ul li{
	padding: 1rem 0 0 3rem;
	}
	.process-key ul li:before{

		top: 0rem;
		left: -4rem;
}
}



/*PROGRESS DIAGRAM*/
.process-diagram ul{
    list-style-type: none;

}

.step-number{
    position:absolute;
    width:0px;
    height:0px;
    left:-098765px;
}

.progress-card {
	width: 100%;
	position: relative;
	min-height: 100px;
	margin-bottom: 8rem;
	text-decoration: none;
	background: #fff;
	box-shadow: 0px 3px 18px 8px rgba(18, 38, 63, 0.15);
}



/* FOR STEPS WHICH ARE NOT 100% width e.g notes in leui - steps 6 & 7*/

@media screen and (min-width: 1025px) {
	.progress-card-small{
		width: 45%;
		display:inline-block;

	}

	.progress-card-small .progress-inner{
		padding: 5rem 2rem;
	}


	.progress-card-small .step-indicator
	{
		width:unset!important;
	}



	.progress-card-small.left-card{
		margin-right: 9%;
	}

	.progress-card-small.left-card .step {
		right: -68vw;
	}



}






.process-diagram h2:before{
    content:"";
    width: 35px;
    height:35px;
    position:absolute;
    top: 0.5rem;
	left: -4.5rem;
}
@media screen and (max-width: 1023px) {
	.process-diagram h2:before{
		background-size: contain!important;
}
}
@media screen and (min-width: 1024px) {
	.process-diagram h2:before{
		width: 46px;
		height:41px;
		top:0.5rem;
		left:-6rem;
	}
}



/*PROGRESS INFO BUTTONS*/

.progress-card .step-button button {
	background-color: #097faf;
	border-radius: 30rem;
    text-indent: -9876543;
    background-image:  url(../images/icons/info.png);
    background-position: center center;
	background-repeat:no-repeat;
	z-index:3;

}


.close-card{
	margin:0px;
	position:absolute;
	bottom:1rem;
	right:1rem;
	padding:0.5rem;

}


@media screen and (max-width: 1024px) {

	.step {
		padding-bottom:8rem!important;

	}
    .step-button{
        text-align:right;
	}
	.progress-card .step-button {
		z-index:3;
		position:relative;

	}
    .progress-card .step-button button {
        border: 3px solid #fff;
        width: 60px;
        height: 60px;
        background-size:33%;

    }
}
@media screen and (min-width: 1025px) {
    .progress-card .step-button button {
        position:absolute;
        border: 6px solid #fff;
        width: 100px;
        height: 100px;
		right: -5rem;
		bottom: 0px;
		top:0px;
		margin-top:auto;
		margin-bottom:auto;
	}

	#block-progress.bottom-margin {
		margin-bottom: 300px;
	}
}


/*PROGRESS INDICATOR ARROWS*/

.progress-card:before {
    content: "";
    width: 2px;
    height: 100px;
    position: absolute;
    bottom: -10rem;
    left: 6rem;
    border-left: dotted 2px #038761;
    z-index: -1;
}
.progress-card:after {
    content:"";
    width: 22px;
    height:28px;
    position:absolute;
    bottom:-6rem;
    left:5rem;
    z-index:0;
    background:  url(../images/icons/progress-arrow.png) right center no-repeat;
}

.progress-card.status-uncomplete:before {
    border-left: dotted 2px #878484;

}
.progress-card.status-uncomplete:after {
    background:  url(../images/icons/progress-arrow-uncompleted.png) right center no-repeat;
}


/*PROGRESS INDICATOR REMOVE FOR LAST LIST ITEM */

#process-step-list li:last-child:after,
#process-step-list li:last-child:before{
	display:none;
}








/*PROGRESS INNER LAYOUT*/



.step-dates span{
    display:block;
}

@media screen and (max-width: 1023px) {
    h2.step-indicator{
        margin-bottom: 2rem;
	}
	.progress-inner {
		padding: 3rem 3rem 3rem 5rem;
		position:relative;
	}
}


@media screen and (min-width: 1024px) {
	.progress-inner {
		padding: 3rem;
		display:flex;
		position:relative;
	}
	.process-diagram h2{
		margin:0px 0px 0px 6rem;
		width:60%;
		padding-right:2rem;
		line-height:3.4rem;
	}
	.step-dates{
		width:40%;
		margin-right:5rem;
		text-align:right;
		font-size:1.6rem;
	}

}
@media screen and (min-width: 1300px) {
	.progress-inner {
		padding:4rem;
	}
	.step-dates{
		font-size:1.7rem;
	}
}
@media screen and (min-width: 1500px) {
	.progress-inner {
		padding:5rem;
	}

}
@media screen and (min-width: 1700px) {
	.progress-inner {
		padding:6rem;
	}
	.step-dates{
		font-size:1.9rem;
	}
}







/*STATUS COMPLETE*/

.status-complete {
    background: #038761;
    border: 8px solid #038761;
}
.process-diagram .status-complete h2:before{
    background:  url(../images/icons/progress-icon-tick.png) right center no-repeat;
}

.status-complete .progress-inner span,
.status-complete .progress-inner h2 {
	color: #fff;
}



/*STATUS IN_PROGRESS*/

.status-in-progress {
	background: #fff;
	border: 8px solid #038761;
}
.process-diagram .status-in-progress h2:before{
     background:  url(../images/icons/progress-icon-in-progress.png) right center no-repeat;
}

.status-in-progress span,
.status-in-progress h2 {
	color: #038761;
}

/*STATUS UNCOMPLETE*/

.status-uncomplete {
	background: #fff;
	border: 8px solid #ccccc0;
}



.process-diagram .status-uncomplete h2:before{
    background:  url(../images/icons/progress-icon-uncomplete.png) right center no-repeat;
}


.progress-card .detail {
	position: relative;
	top: 0px;
	right: 0px;
}





/*STATUS WINDOW DETAILS*/


.step{
    border:4px solid #097faf;
    background-color:#fff;
	padding:3rem;
	position:relative;
}
.step h3{
    color:#097faf;
}
.step h2,
.step h3,
.step h4,
.step h5{
    margin-bottom:1rem;
}
.step h4{
    margin-top:2rem;
}

.step h3{
	width:85%;
}



@media screen and (max-width: 700px) {
    .step{
        margin-top:-8rem;

    }
}
@media screen and (min-width: 1024px) {
    .step{
        border:6px solid #097faf;

    }
    .progress-card .step {
        position: absolute;
   		top: 0px;
		right: -40vw;
		width: 30vw;
		background: #fff;
		box-shadow: 0px 3px 18px 8px rgba(18, 38, 63, 0.15);
		padding: 6rem 4rem 8rem 4rem;
	}
}



/*ICONS*/

.icon-tick {
	width: 30px;
}






/*" COLUMN PAGE LAYOUTS*/
@media screen and (max-width: 1279px) {
    .page-col-right,
    .page-col-left{
        width:100%!important;
        margin-left:0px!important;
    }

}

@media screen and (min-width: 1280px) {
.four.columns.page-col-right {
    width: 35.667%!important;
}
}

@media screen and (min-width: 1280px) {
    .page-col-right{
    float:right;
    }
}



.page-col-right.form{
    padding:4rem;
}


/*ACCORDIANS*/
.accordion-container .card{
    padding: 2rem 4rem 2rem 2rem;
}


.accordion-container h2{
    margin-bottom:0px;
 }

.accordion-container .hidden {
	display: none;
}

.accordion-container .accordion-container h2 {
	margin: 1em 0 0 0;
}

.accordion-container h2 button {
	all: inherit;
    height:120px;
    margin: 0;
    padding-left:2rem;
    padding-right:2rem;
    width:100%;
	cursor: pointer;

}

.accordion-container h2 button:after {
    content:"";
    width: 130px;
    height:130px;
    position:absolute;
    top:0px;
    right:-10rem;
    background:  url(../images/icons/accordian-open.png) right center no-repeat;
}

.accordion-container h2 button:hover,
.accordion-container h2 button:focus {
	background-color: #f4f4f4;
}

.accordion-container h2 button[aria-expanded="true"]:after {
	background-image: url(../images/icons/accordian-close.png);
}

.accordion-container .accordion-panel {
	margin: 0;
	padding: 0.5em;

}
@media screen and (max-width: 765px) {
    .accordion-container h2 button{
        width:75%;
    }
    .accordion-container h2 button:after {
        content:"";
        background-size: 80%;
        right:-10rem;
    }
}
@media screen and (min-width: 766px) and (max-width: 1024px) {
    .accordion-container h2 button{
        width:90%;
    }
    .accordion-container h2 button:after {
        content:"";
        background-size: 80%;
        right:-8rem;
    }
}



/*COMMUNICATION*/

.communication  p.message-details span.name{
    border-right:2px solid #000;
    display:inline-block;
    padding-right:1rem;
    margin-right:0.5rem;
}
.communication .ea-message p.message-details{
    color:#038761;

}
.communication .user-message p.message-details{
    color:#097faf;

}
.communication-block{
    margin-bottom:5rem;
    display:flex;
    width:100%;
    background-repeat:no-repeat;
}
.communication-block p.message-content{
    margin-top:3rem;
}
.ea-message{
    background-image:url(../images/icons/message-ea.png);

}
.user-message{
    background-image:url(../images/icons/message-user.png);

}
.message{
    width: 100%;
    padding: 4rem;
    text-align: left;
    position: relative;
    border-radius: 1rem;
	box-shadow: 0px 3px 18px 8px rgba(18, 38, 63, 0.15);
}
@media screen and (max-width: 767px) {
    .communication-block{
        background-size: 100px;;
        background-position-x: right 20px;
        background-position-y: top 20px;
    }
    .communication p.message-details{
        width:70%;
        display:block;
    }
}
@media screen and (min-width: 768px) {

    .ea-message{
      padding-left:23%;
    }
    .user-message{
      padding-right:23%;
      background-position: right 0px;
}
}

@media screen and (min-width: 768px) and (max-width:1580px) {

.communication-block{
    background-size: 130px;

}
}

/*COMMUNICATION EA MESSAGE*/
.ea-message .message{
    border: 1rem solid #038761;
}

@media screen and (min-width: 760px) {
.ea-message .message:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 2.5rem solid transparent;
    border-right: 2.5rem solid #038761;
    border-top: 2.5rem solid transparent;
    border-bottom: 2.5rem solid transparent;
    left: -6rem;
  }

.ea-message .message:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 2.5rem solid transparent;
    border-right: 2.5rem solid #fff;
    border-top: 2.5rem solid transparent;
    border-bottom: 2.5rem solid transparent;
    left: -4.5rem;
    top: 4rem;
  }
}




/*COMMUNICATION USER MESSAGE*/
.user-message .message{
    border: 1rem solid #097faf;
}
@media screen and (min-width: 760px) {
.user-message .message:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 2.5rem solid #097faf;
    border-right: 2.5rem solid transparent;
    border-top: 2.5rem solid transparent;
    border-bottom: 2.5rem solid transparent;
    right: -6rem;
  }

.user-message .message:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 2.5rem solid #fff;
    border-right: 2.5rem solid transparent;
    border-top: 2.5rem solid transparent;
    border-bottom: 2.5rem solid transparent;
    right: -4.5rem;
    top: 4rem;
  }
}



/*COMMUNICATION USER MESSAGE*/

.footer{
	width: 100%;
	margin: auto;
	background: #097FAF;
	color: #fff;
	padding: 0px;
	background-image: linear-gradient( to right, #005b97, #013353);
}
.footer .row{

	padding: 8rem 0 8rem 0px;

}
.footer a{
	color:#fff;
	text-decoration:none;
}
.footer ul{
	list-style-type:none;
	margin-left:1rem;
}
.footer ul li{
	padding-top:1rem;
	padding-bottom:1rem;
	padding-left:2rem;
	margin:0px;
	position:relative;
	border-bottom: 1px solid #fff;

}
.footer ul li:before{
	content: "";
	width: 20px;
	height: 20px;
	position: absolute;
	top: 1.5rem;
	left: -1rem;
	background-image:  url(../images/icons/footer-arrow.png);
	background-position: center center;
	background-repeat:no-repeat;
	background-size:contain;
	z-index:1;

}









/*HOMEPAGE POSITIONING*/

.home-page .login-form .card{
	position: relative;
	z-index: 1;
}

.block-home-resources .notice-login{
	color:#0a6c8a;
	font-size:90%;
}


@media screen and (min-width: 1024px) {
	.block-home-resources{
margin-bottom:20rem;
	}
.block-home-resources .card-row{
	display: flex;
	flex-wrap: wrap;
	justify-content:center;
	}
	.block-home-resources .card-row:first-child{
		justify-content: space-between;
	}
	.block-home-resources .card-row:nth-child(3){
		margin-bottom:-10rem;
	}
	.block-home-resources .card-row .card{
		width:30%;
		margin-right: 2%;
	}
}

@media screen and (min-width: 1280px) {

	.home-page .login-form .card{
		margin-top: -10rem;
		margin-bottom: -15rem;
	}

}
@media screen and (min-width: 1280px) and (max-width:1680px) {
	.container.login-form {
		width: 85%;
	}
}

@media screen and (min-width: 1500px) {
	.home-page .login-form .card{
		margin-top: -20rem;
		margin-bottom: -17rem;

	}

}






